export default {
    props: {
        /**
         * ORientation of the group - vertical or horizontal
         */
        orientation: {
            type : String,
            default: 'horizontal',
        },
        /**
         * Tight froup has no margin between buttons
         */
        tight: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        wrapperClass() {
            let result = ['uk-flex', 'uk-button-group', 's-button-group'];
            
            if (this.orientation === 'vertical') {
                result.push('uk-flex-column');
                result.push('s-button-group--vertical');
            } else {
                result.push('s-button-group--horizontal');
            }
            
            if (this.tight) {
                result.push('s-button-group--tight');
            }
            
            return result;
        }
    }
}
